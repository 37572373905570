
import axios from "axios";
import config from "../../constants/config";

// const url = config.API_URL + '/game/marketplace/events';
const url1 = config.API_URL + '/admin/advertisingCards';

export async function getAdvertisements(token) {
  console.log('called getAdvertisements() method');
  return await axios.get(url1, {
    headers: {
      Authorization: token,
    },
  });
}

export async function createAdvertisement(token, imageUrl, adtype, adOutsideAppRouting, adWithinAppRouting, adRegion, regionalPriority,adLink) {
  console.log('called createAdvertisement() method');
  return await axios.post(url1, {
    imageUrl: imageUrl,
    routeType: adLink,
    regionalPriority: parseInt(regionalPriority),
    region: adRegion,
    withinAppRouting: adWithinAppRouting,
    outsideAppRouting: adOutsideAppRouting,
    type: adtype
  }, 
  {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  });
}


export async function deleteAdvertisement(token,adId) {
  console.log('called createAdvertisement() method',adId);
  return await axios.delete(url1 + '/'+adId, 
  {params:{
    cardId: adId
  },
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  });
}


export async function updateAdvertisement(token,adId,imageUrl, adtype, adOutsideAppRouting, adWithinAppRouting, adRegion, regionalPriority,adLink) {
  console.log('called updateAdvertisement() method');
  return await axios.put(url1,{
           _id: adId,
      imageUrl: imageUrl, //required
      routeType: adLink,
      regionalPriority: regionalPriority,
      region: adRegion,
      withinAppRouting: adWithinAppRouting,
      outsideAppRouting: adOutsideAppRouting,
      type: adtype
    
  } ,
  {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  });
}