import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import JsonView from "react18-json-view";
import { getUserReports } from "../../../../API/ReportsAPI/ReportsApi";
import { UserDataProvider } from "../UserInLineEditView";
const recordCountSelecter = [10, 15, 20, 25, 30];
function UserReports(props) {
  const navigator = useNavigate();
    const {userData,userId} = useContext(UserDataProvider)
  const [userPromotionalOffersData, setUserPromotionalOffersData] = useState(
    []
  );
  const [reportData,setReportData]=useState({})
  const [pageCount, setPageCount] = useState(1);
  const [recordCount, setRecordCount] = useState(recordCountSelecter[0]);
  const [period, setPeriod] = useState("7");
  const [startDate, setStartDate] = useState(dayjs("2024-04-01"));
  const [endDate, setEndDate] = useState(dayjs(new Date()));
  const [currencyType,setCurrencyType]=useState('KARATS');
  
  // alert(startDate)
  const [loading, setLoading] = useState(true);
  useEffect(()=>{
    const token = sessionStorage.getItem("token");
    setLoading(true);
    getUserReports(token,currencyType,userId,period,startDate,endDate).then((res)=>{
        console.log(res.data);
        setReportData(res.data);
        setLoading(false);
    }).catch((err)=>{
        console.log(err)
        setLoading(false);
    })
  },[currencyType,period,startDate,endDate])


  return (
    <div>
      <div style={{ marginTop: "2.5vh" }}>
        <Row
          style={{
            display: "flex",
            alignItems: "flex-end",
            marginBottom: "1.5%",
          }}
        >
          <Col xs={5}>
            {["7", "30", "60", "Lifetime"].map((value) => {
              return (
                <Button
                  style={{ borderRadius: 15, margin: "0 10px" }}
                  variant="outline-secondary"
                  active={period === value}
                  onClick={() => {
                    setPageCount(1);
                    setPeriod(value);
                  }}
                  disabled={loading}
                >
                  {value === "Lifetime" ? value : `Last ${value} Days`}
                </Button>
              );
            })}
          </Col >
          
            <Col xs={3}>
            {["COINS",'KARATS'].map((value) => {
              return (
                <Button
                  style={{ borderRadius: 15, margin: "0 10px" ,backgroundColor:currencyType !== value && "transparent",color:currencyType !== value && "black"}}
                  variant={value === "KARATS" ? "success":"warning" }
                  active={currencyType === value }
                  
                  onClick={() => {
                    setCurrencyType(value);
                  }}
                  disabled={loading}
                  
                >
                  {value}
                </Button>
              );
            })}
            
            </Col>
          

          <Col style={{ display: "flex", justifyContent: "flex-end" }} xs={4}>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(newValue) => {
                    setPeriod("");
                    setPageCount(1);
                    setStartDate(newValue);
                  }}
                  minDate={dayjs("2024-04-01")}
                  maxDate={dayjs(new Date())}
                  disabled={loading}
                />
              </LocalizationProvider>
            </div>
            <div style={{ marginLeft: "1%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(newValue) => {
                    setPeriod("");
                    setPageCount(1);
                    setEndDate(newValue);
                  }}
                  minDate={dayjs("2024-04-01")}
                  maxDate={dayjs(new Date())}
                  disabled={loading}
                />
              </LocalizationProvider>
            </div>
          </Col>
        </Row>
      </div>

      <div style={{display:"flex",justifyContent:"center",alignItems:"center",paddingTop:"3%"}}>
        <JsonView src={reportData} style={{fontSize:"1.5vw"}}/>
      </div>
    </div>
  );
}

export default UserReports;
