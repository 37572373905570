import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import "./Users.css"
import { Button, Dropdown, DropdownButton, Form, InputGroup } from 'react-bootstrap';
import { Paper } from '@mui/material';
import { DataGrid ,GridColDef} from '@mui/x-data-grid';
import { getUsersData } from '../../API/HomeAPI/HomeAPI';
import { Outlet, useNavigate } from 'react-router-dom';
import { RiEditCircleFill } from 'react-icons/ri';
import { IoMdAddCircle } from 'react-icons/io';
import { FaUser } from 'react-icons/fa';
import { TiDelete } from 'react-icons/ti';
import UsersTableView from './UsersTableView/UsersTableView';
function Users(props) {

    return (
        <Layout>
          
          <Outlet />
         
        </Layout>
    );
}

export default Users;


