import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { UserProfileCom } from '../UserInLineEditView';
import RInput from '../../../../components/RInput';

function UserSubscriptionManagement(props) {
    return (
        <div>
              <Row>
        <Col xs={2}>
          <UserProfileCom />
        </Col>
        <Col xs={10}>
          <div>
            <div>
              <h1 className="sideHeadingSty">Header</h1>
              <div>
                <Row className="input-row">
                  <Col xs={6}>
                    <RInput lable={"User’s Lifetime Value"} />
                  </Col>
                  <Col xs={6}>
                    <RInput lable={"User’s Lifetime Value"} />
                  </Col>
                </Row>
              </div>
            </div>
          
          </div>
        </Col>
      </Row>
        </div>
    );
}

export default UserSubscriptionManagement;