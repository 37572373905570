import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { UserDataProvider, UserProfileCom } from "../UserInLineEditView";
import RInput from "../../../../components/RInput";

function UserSettings(props) {
  const {userData}=useContext(UserDataProvider);
  return (
    <div>
      <div>
        <Row>
          <Col xs={2}>
            <UserProfileCom />
          </Col>
          <Col xs={10}>
            <div>
              <div>
                <h1 className="sideHeadingSty">Responsible Gaming Limits</h1>
                <div>
                  <Row className="input-row">
                    <Col xs={4}>
                      <Row>
                        <Col xs={2}  style={{display:"flex",alignItems:"flex-end",justifyContent:"flex-end"}}>
                            <p className="inputLableSty">Day</p>
                        </Col>
                        <Col xs={10} >
                          <RInput lable={"Deposit Limits Per:"} value={userData?.gamingLimits?.depositLimit?.dailyLimit} userUpdateType="limits" keyName={"dailyLimit"} />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={2} style={{display:"flex",alignItems:"flex-end",justifyContent:"flex-end"}}>
                            <p className="inputLableSty">Week</p>
                        </Col>
                        <Col xs={10}>
                          <RInput lable={""}  value={userData?.gamingLimits?.depositLimit?.weeklyLimit}  userUpdateType="limits"  keyName={"weeklyLimit"}/>
                        </Col>
                      </Row>  
                       <Row>
                        <Col xs={2} style={{display:"flex",alignItems:"flex-end",justifyContent:"flex-end"}}>
                            <p className="inputLableSty">Year</p>
                        </Col>
                        <Col xs={10}>
                          <RInput lable={""} value={userData?.gamingLimits?.depositLimit?.monthlyLimit}  userUpdateType="limits"  keyName={"monthlyLimit"}/>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={4}>
                      <RInput lable={"Max Offer limit"} value={userData?.gamingLimits?.maxOfferLimit}  userUpdateType="limits"  keyName={"maxOfferLimit"}/>
                    </Col>
                    <Col xs={4}>
                      <RInput lable={"Session Time Limit"} value={userData?.gamingLimits?.sessionTimeLimit}  userUpdateType="limits"  keyName={"sessionTimeLimit"}/>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default UserSettings;
