import React, { useEffect, useState } from "react";
import "./DashBoard.css";
import Layout from "../../components/Layout/Layout";
import { Col, Row } from "react-bootstrap";
import { getGraphInfo, getStockInfo } from "../../API/HomeAPI/HomeAPI";
import { LineChart } from "@mui/x-charts";
import { Radio } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
function DashBoard(props) {
  const [stockData,setStockData]=useState({});
  const [graphData,setGraphData]=useState({});
  const [year,setYear]=useState(dayjs());
  const [month,setMonth]=useState(dayjs(year)); 
  const [selectMonth,setSelectMonth]=useState(false);
  const [loading,setLoading]=useState(false);
  const windowHeight=window.innerHeight; 
  useEffect(()=>{
    
    const token=sessionStorage.getItem("token");
    getStockInfo(token).then((res)=>{
      setStockData(res.data);
    }).catch((err)=>{  
      console.log(err);
    })
  },[])
console.log(dayjs("2024"))
 useEffect(()=>{
  setLoading(true);
  // setGraphData({});
  const token=sessionStorage.getItem("token");
  getGraphInfo(token,year.year(),selectMonth ? month.month()+1 : "").then((res)=>{
    setGraphData(res.data);
  }).catch((err)=>{
      console.log(err);
  }).finally(()=>{
    setLoading(false);
  })
 },[selectMonth,year,month])

  const monthsLabels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  return (
    <Layout >
      
      <Row style={{margin:"1.5vw "}}>
      <h1  className="sideHeadingSty">
      User and Offers Insights 
      </h1>
        <Col >
          <LabelData label={"Total Users"} value={stockData?.totalUsers} />    
        </Col>
        <Col >
          <LabelData label={"Offers Settled"} value={stockData?.settledOffers} />    
        </Col>
        <Col >
          <LabelData label={"Offers Posted"} value={stockData?.postedOffers} />    
        </Col>
      </Row>
      
      <Row style={{margin:"2vw "}}>
        <Col >
      <div className="graph-cont">
        <Row>
          <Col>
          <h1 className="sideHeadingSty" style={{margin:"0 0 1.5vh",color:"white"}}> Offers Insights </h1>
          </Col>
      <Col style={{display:"flex",alignItems:"center",justifyContent:"flex-end",paddingBottom:"1.5vh"}}>
      <LocalizationProvider dateAdapter={AdapterDayjs} >
        <DatePicker
          value={year}
          onChange={(value)=>{setYear(value ??dayjs() )}}
          minDate={dayjs("2024")}
          maxDate={dayjs()}
          openTo="year"
          views={['year']}
          className="yearDatePicker"
        />

    </LocalizationProvider>

    <Row style={{marginLeft:"1%"}}>
      <Col xs={9} >
    <LocalizationProvider dateAdapter={AdapterDayjs} >
        <DatePicker
          disabled={!selectMonth}
          value={month}
          onChange={(value)=>{setMonth(value ?? dayjs() )}}
          maxDate={dayjs()}
          openTo="month"
          views={['month']}
          className={"monthDatePicker "+ (!selectMonth ? "monthDatePickerInActive" : "")}
          
        />
    </LocalizationProvider>
    </Col>
    <Col xs={1} style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
      <Radio  checked={selectMonth} label="Solid" variant="solid" onClick={()=>{setSelectMonth(prev=>!prev)}} color="warning"  style={{backgroundColor:"white"}}/>
    </Col>
    </Row>

      </Col>
      
      </Row>
        <div className="graph-sub">
        <LineChart
             loading={loading}
              series={[
                { data: graphData?.KARATS ?? [], label: 'KaratsOfferData' },
                { data: graphData?.COINS ?? [], label: 'CoinsOfferData' },
                // { data: PromoOfferData, label: 'PromoOfferData' },
              ]}
              xAxis={[{ scaleType: 'point', data: !selectMonth && graphData?.TYPE==="Year" ? monthsLabels :getDaysInMonth(year.year(),month.month())  }]}
            height={windowHeight/1.9}
          />
          
        </div>

      </div>
      </Col>

      </Row>
     
     {/* <JsonView src={stockData}/> */}
    </Layout>
  );
}

export default DashBoard;



const LabelData = ({ label, value }) => {
  return (
    <div className="LabelData-container">
      <h1 className="LabelData-label">{label}</h1>
      {label === "Total Users" ? (<>
        <p className="LabelData-value"><input className="value-cont" disabled value={value}/></p>
        <p className="LabelData-value" style={{visibility:"hidden"}}> s<span className="value-cont"></span></p>
      </>
        
        
      ) : (
        <>
          <p className="LabelData-value"><span>Coins:</span> <input className="value-cont" disabled value={value?.coins}/></p>
          <p className="LabelData-value"><span>Karats:</span> <input className="value-cont" disabled value={value?.karats}/></p>
        </>
      )}
    </div>
  );
};




function getDaysInMonth(year, month) {
  if (month === undefined) {
    month=0;
  }
  const date = new Date(year, month + 1, 0); 
  const daysInMonth = date.getDate();
  
  const days=Array.from({ length: daysInMonth }, (_, i) => i + 1);
  if(days.length <=28 ){
    days.push("-","-","-");
  }
  else if(days.length <= 29){
    days.push("-","-");
  }
  else if(days.length <= 30){
    days.push("-");
  }
  return days
}