export const LOGO_FULL_RED = require('../Assets/logos/logo_full_red.png');
export const LOGO_ICON_WHITE = require('../Assets/logos/logo_icon_white.png');
export const LOGO_HORIZONTAL_RED_WHITE = require('../Assets/logos/logo_horizontal_red_white.png');


export const IC_MLB = require('../Assets/Icons/ic_caw_mlb.png');
export const IC_NFL = require('../Assets/Icons/ic_caw_nfl.png');
export const IC_NHL = require('../Assets/Icons/ic_caw_nhl.png');
export const IC_NBA = require('../Assets/Icons/ic_caw_nba.png');
export const IC_MLS = require('../Assets/Icons/ic_caw_mls.png');
export const IC_NCAAB = require('../Assets/Icons/ic_caw_ncaab.png');
export const IC_NCAAF = require('../Assets/Icons/ic_caw_ncaaf.png');
export const IC_NTA = require('../Assets/Icons/ic_caw_nta.png');
export const IC_PGA = require('../Assets/Icons/ic_caw_pga.png');