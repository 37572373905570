import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { UserDataProvider, UserProfileCom } from "../UserInLineEditView";
import RInput from "../../../../components/RInput";
import { Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { HiOutlineArrowSmLeft, HiOutlineArrowSmRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { getUserWalletData } from "../../../../API/UsersAPI/UsersApi";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const recordCountSelecter = [10, 15, 20, 25, 30];
function UserWalletTransactions(props) {
  const navigator = useNavigate();
  const {userData} = useContext(UserDataProvider)
  const [userWalletData,setUserWalletData]=useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [recordCount, setRecordCount] = useState(recordCountSelecter[0]);
  const [period, setPeriod] = useState("7");
  const [startDate, setStartDate] = useState(dayjs("2024-04-01"));
  const [endDate, setEndDate] = useState(dayjs(new Date()));
  // alert(startDate)
  const [loading, setLoading] = useState(true);
  // console.log(userData)
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    console.log(token);
    setLoading(true);
    console.log("params :", token,userData?._id,pageCount,
      recordCount, period,
      startDate.toString(),
      endDate.toString())

    getUserWalletData(
      token,
      userData?._id,
      pageCount,
      recordCount,
      period,
      startDate.toString(),
      endDate.toString()
    )
      .then((res) => {
        if (typeof res.data === "object") {
          setUserWalletData(
            res.data.map((item) => {
              return { ...item };
            })
          );
        }
        // alert(typeof res.data)
        console.log("data fetched", res);
        // alert("users data fetched");
        setLoading(false);
      })
      .catch((error) => {
        // alert("users data fetched" + error);
        console.log("error");
        setLoading(false);
        setUserWalletData([])
      });
  }, [pageCount, recordCount, period,
    startDate,
    endDate]);

  const getColumns = () => {
    const filteredEntries = Object.entries(
      Object.assign({}, ...userWalletData)
    ).filter(
      ([key, value]) =>
        typeof value !== "function" &&
        typeof value !== "object" &&
        typeof value !== "symbol" &&
        typeof value !== "undefined"
    );
    const filteredObject = Object.fromEntries(filteredEntries);
    const dataKeys = Object.keys(filteredObject);
    return dataKeys.map((item, index) => {
      return index === 0
        ? {
            field: item,
            headerName: item,
            align: "center",
            headerAlign: "center",
            sortable: false,
            editable: false,
            // renderCell: (params) => {
            //   return <CustomCellComponent value={params.value} />;
            // },
            width: 150,
          }
        : {
            field: item,
            headerName: item,
            align: "center",
            headerAlign: "center",
            sortable: false,
            editable: true,
          };
    });
  };

  const columns: GridColDef[] = [...getColumns()];

  const rows = userWalletData.map((item, index) => {
    const filteredEntries = Object.entries(item ?? {}).filter(
      ([key, value]) =>
        typeof value !== "function" &&
        typeof value !== "object" &&
        typeof value !== "symbol" &&
        typeof value !== "undefined"
    );
    const filteredObject = Object.fromEntries(filteredEntries);
    return { ...filteredObject, id: index };
  });
  return (
    <>
      <Row>
        <Col xs={2}>
          <UserProfileCom />
        </Col>
        <Col xs={10}>
          <div>
            <div>
              <h1 className="sideHeadingSty">Balances</h1>
              <div>
                <Row className="input-row">
                  <Col xs={4}>
                    <RInput lable={"Total Balance _id: "+userData?.walletDetils?._id} disabled={true} value={userData?.walletDetils?.availableAmount + userData?.walletDetils?.inPlayAmount}/>
                  </Col>
                  <Col xs={4}>
                    <RInput lable={"Current Available Funds"} disabled={true} value={userData?.walletDetils?.availableAmount}/>
                  </Col>
                  <Col xs={4}>
                    <RInput lable={"Current In-Play Funds"} disabled={true}   value={userData?.walletDetils?.inPlayAmount}/>
                  </Col>
                </Row>
              </div>
              <div>
                <Row className="input-row">
                  <Col xs={4}>
                    <RInput lable={"Total Coins _id: "+userData?.coinsWalletDetils?._id} disabled={true} value={userData?.coinsWalletDetils?.availableAmount + userData?.coinsWalletDetils?.inPlayAmount}/>
                  </Col>
                  <Col xs={4}>
                    <RInput lable={"Current Available Coins"} disabled={true} value={userData?.coinsWalletDetils?.availableAmount}/>
                  </Col>
                  <Col xs={4}>
                    <RInput lable={"Current In-Play Coins"} disabled={true} value={userData?.coinsWalletDetils?.inPlayAmount}/>
                  </Col>
                </Row>
              </div>
            </div>

            <h1 className="sideHeadingSty">User Metrics & Returns</h1>
            <div style={{ width: "19vw" }}>
              <RInput lable={"Current In-Play Funds"} disabled={true}/>
              <RInput lable={"Current Login"} disabled={true}/>
            </div>
          </div>
        </Col>
      </Row>
      <div>
        <div>
          <h1 className="sideHeadingSty" style={{ marginLeft: "1%" }}>
            Balance History
          </h1>
        </div>
        <div>
          <Row
            style={{
              display: "flex",
              alignItems: "flex-end",
              marginBottom: "1.5%",
            }}
          >
            <Col>
              {["7", "30", "60", "Lifetime"].map((value) => {
                return (
                  <Button
                    style={{ borderRadius: 15, margin: "0 10px" }}
                    variant="outline-secondary"
                    active={period === value}
                    onClick={() => {
                      setPageCount(1);setPeriod(value);
                    }}
                    disabled={loading}
                  >
                    {value === "Lifetime" ? value : `Last ${value} Days`}
                  </Button>
                );
              })}
            </Col>

            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              <div>
                
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newValue) => {setPeriod("");setPageCount(1);setStartDate(newValue);}}
                    minDate={dayjs("2024-04-01")}
                    maxDate={dayjs(new Date())}
                    disabled={loading}
                  />
                </LocalizationProvider>
              </div>
              <div style={{marginLeft:"1%"}}>
              <LocalizationProvider dateAdapter={AdapterDayjs} >
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newValue) => {setPeriod("");setPageCount(1);setEndDate(newValue);}}
                    minDate={dayjs("2024-04-01")}
                    maxDate={dayjs(new Date())}
                    disabled={loading}
                  />
                </LocalizationProvider>
              </div>
            </Col>
          </Row>
        </div>
        <Paper sx={{ height: "65vh", width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                // paginationModel: { pageSize: recordCount },
                meta: { hasNextPage: true },
              },
            }}
            pageSizeOptions={[5, 10, 15]}
            paginationMode="client"
            pagination
            // estimatedRowCount={500}
            // hideFooterSelectedRowCount={true}
            rowSpacingType="border"
            // autoPageSize
            hideFooterPagination
            hideFooter
            rowCount={1000}
            onPaginationMetaChange={() => {
              alert("changed");
            }}
            // checkboxSelection
            sx={{ border: 0 }}
            loading={loading}
            onProcessRowUpdateError={(error) => {
              alert("on Process Row Update Error");
            }}
            disableRowSelectionOnClick
            onPaginationModelChange={(model) => {
              // setPaginationModel(model);
              // console.log(model);
              // getMoreData();
            }}
            onRowDoubleClick={(item) => {
              alert(item?.row?._id);
            }}
            // autosizeOptions={{
            //     columns: ['dob', 'status', 'createdBy'],
            //     includeOutliers: false,
            //     includeHeaders: false,
            //   }}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "1%",
              paddingRight: "2%",
            }}
          >
            <div
              style={{
                width: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  // border: "1px solid black",
                  width: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ margin: "0", color: "black", margin: "0 0.5vw" }}>
                  Row per page
                </p>
              </div>

              <div
                style={{
                  // border: "1px solid black",
                  width: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Form.Select
                  size="sm"
                  onChange={(event) => {
                    setRecordCount(event.target.value);
                  }}
                  disabled={loading}
                >
                  {recordCountSelecter.map((value, index) => {
                    return <option key={index + "values"}>{value}</option>;
                  })}
                </Form.Select>

                <p
                  style={{ margin: "0", color: "black", margin: "0 0.5vw" }}
                ></p>
              </div>
              <Button
                onClick={() => {
                  setPageCount((page) => page - 1);
                }}
                disabled={pageCount <= 1 || loading}
              >
                <HiOutlineArrowSmLeft />
              </Button>

              <div
                style={{
                  // border: "1px solid black",
                  width: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ margin: "0", color: "black", margin: "0 0.5vw" }}>
                  {pageCount}
                </p>
              </div>

              <Button
                onClick={() => {
                  // getMoreData();
                  setPageCount((page) => page + 1);
                }}
                disabled={loading}
              >
                <HiOutlineArrowSmRight />
              </Button>
            </div>
          </div>
        </Paper>
      </div>
    </>
  );
}

export default UserWalletTransactions;
