import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import LoginPage from '../Pages/LoginPage/LoginPage';
import DashBoard from '../Pages/DashBoard/DashBoard';
import Users from '../Pages/Users/Users';
import Advertising from '../Pages/Advertising/Advertising';
import EmailSettings from '../Pages/EmailSettings/EmailSettings';
import LeagueConfig from '../Pages/LeagueConfig/LeagueConfig';
import Reports from '../Pages/Reports/Reports';
import DBSettings from '../Pages/DBSettings/DBSettings';
import Archive from '../Pages/Archive/Archive';
import UserInLineEditView from '../Pages/Users/UserInLineEditView/UserInLineEditView';
import UsersTableView from '../Pages/Users/UsersTableView/UsersTableView';

function Root(props) {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route path="/DashBoard" element={<DashBoard />} />
                <Route path="/Users" element={<Users />} >
                    <Route path="UsersTableView" element={<UsersTableView />} />
                    <Route path="UserInLineEditView/:id" element={<UserInLineEditView />} />
                </Route>
                <Route path="/Advertising" element={<Advertising/>}/>
                <Route path="/EmailSettings" element={<EmailSettings/>}/>
                <Route path="/LeagueConfig" element={<LeagueConfig/>}/>
                <Route path="/Reports" element={<Reports/>}/>
                <Route path="/DBSettings" element={<DBSettings/>}/>
                <Route path="/Archive" element={<Archive/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default Root;