import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { LOGO_ICON_WHITE } from "../../../../constants/images";
import { Image } from "react-bootstrap";
import "./UserAccountInfo.css";
import RInput from "../../../../components/RInput";
import { UserDataProvider } from "../UserInLineEditView";
function UserAccountInfo(props) {
  const {userData,setUpdatedData}=useContext(UserDataProvider);
  return (
    <div>
      <div>
        <h1 className="sideHeadingSty">Account Info</h1>
        <div>
          <Row className="input-row">
            <Col xs={6}>
              <RInput lable={"First Name"} value={userData?.firstName} keyName={"firstName"}/>
            </Col>
            <Col xs={6}>
              <RInput lable={"Last Name"} value={userData?.lastName} keyName={"lastName"}/>
            </Col>
          </Row>
        </div>
      </div>
      <div>
        <Row className="input-row">
          <Col xs={6}>
            <RInput lable={"Rabbet Username"}  value={userData?.username} keyName={"username"}/>
          </Col>
          <Col xs={6}>
            <RInput lable={"Rabbet Nickname"}  value={userData?.nickName} keyName={"nickName"}/>
          </Col>
        </Row>

        <Row className="input-row">
          <Col xs={6}>
            <RInput lable={"Last Four (4) Social Security Number"} value={"####"} disabled={true}/>
          </Col>
          <Col xs={6}>
            <RInput lable={"Phone Number"} value={userData?.phoneNumber} keyName={"phoneNumber"}/>
          </Col>
        </Row>
      </div>

      <div>
        <h1 className="sideHeadingSty">Address Information</h1>
        <Row className="input-row">
          <Col xs={6}>
            <RInput lable={"Address Line 1"} value={userData?.addressLine1} keyName={"addressLine1"}/>
          </Col>
          <Col xs={6}>
            <RInput lable={"Address Line 2"} value={userData?.addressLine2} keyName={"addressLine2"}/>
          </Col>
        </Row>
        <Row className="input-row">
          <Col xs={6}>
            <RInput lable={"State"} value={userData?.state} keyName={"state"}/>
          </Col>
          <Col xs={6}>
            <RInput lable={"Zip"} value={userData?.zip} keyName={"zip"}/>
          </Col>
        </Row>
      </div>

      <div>
        <h1 className="sideHeadingSty">Terms Of Use</h1>
        <Row className="input-row">
          <Col xs={6}>
            <RInput lable={"Agreed To Latest Terms?"} value={userData?.lastAgreedTerms?.tncLatestVersion} keyName={"zip"}/>
          </Col>
          <Col xs={6}>
            <RInput lable={"Date Terms Were Agreed"} value={userData?.lastAgreedTerms?.agreedOn} keyName={"agreedOn"}/>
          </Col>
        </Row>
        <Row className="input-row">
          <Col xs={6}>
            <RInput lable={"Latest Agreed Version"} value={userData?.lastAgreedTerms?.tncVersion} keyName={"tncVersion"} />
          </Col>
          <Col xs={6}>{/* <RInput lable={""}/> */}</Col>
        </Row>
      </div>

      <div>
        <h1 className="sideHeadingSty">User Activity</h1>
        <Row className="input-row">
          <Col xs={6}>
            <RInput lable={"Total Offers"} value={userData?.offersCount} keyName={"offersCount"} disabled={true}/>
          </Col>
          <Col xs={3}>
            <RInput lable={"Proposed"} value={userData?.offersCreatedCount} keyName={"offersCreatedCount"} disabled={true}/>
          </Col>
          <Col xs={3}>
            <RInput lable={"Accepted"} value={userData?.offersAcceptedCount} keyName={"offersAcceptedCount"} disabled={true}/>
          </Col>
        </Row>
      </div>
      
    </div>
  );
}

export default UserAccountInfo;
