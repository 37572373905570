import axios from "axios";
import config from "../../constants/config";



export const restartServer = async (token) => {
    console.log('called Restart server Method');
    const url = config.API_URL + "/admin/restartServer" 
    return await axios.get(url, {
      headers: {
        Authorization: token, // Correct placement of Authorization header
        'Content-Type': 'application/json', // Ensure content type is set to JSON
      },
    });
  };


export default restartServer