import axios from "axios"
import config from "../../constants/config"

export const getPromoReports = async (token,page,limit) => {
    const url = config.API_URL + "/admin/report/promoOffers"
    return await axios.get(url, {
      headers: {
        Authorization: token,
      },
      params:{
        page,limit
      }
    })
  }

  export const getSinglePromoReports = async (token,id) => {
    const url = config.API_URL + "/admin/report/"+id
    return await axios.get(url, {
      headers: {
        Authorization: token,
      },
     
    })
  }

  export const getUserReports = async (token,currencyType,userId,period,startDate,endDate) => {
    const url = config.API_URL + "/admin/userPerformanceStats"
    return await axios.get(url, {
      headers: {
        Authorization: token,
      },
      params:{
        currencyType,
        userId,
        period,
        startDate,
        endDate
      }
     
    })
  }

  export const getAllReports = async (token,currencyType,period,startDate,endDate) => {
    const url = config.API_URL + "/admin/userPerformanceStats"
    return await axios.get(url, {
      headers: {
        Authorization: token,
      },
      params:{
        currencyType,
        period,
        startDate,
        endDate
      }
     
    })
  }