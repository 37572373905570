import React,{ useContext, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import RInput from "../../../../components/RInput";
import { UserDataProvider } from "../UserInLineEditView";
import { updateUser } from "../../../../API/UsersAPI/UsersApi";

function UserSecurityStatus(props) {

  
  const {userData,userId,setReloadUserData}=useContext(UserDataProvider);
  const [resetLoginAtmLoading,setResetLoginAtmLoading]=useState(false);
  return (
    <div>
      <div>
        <h1 className="sideHeadingSty">Login Info</h1>
        <div>
          <Row className="input-row">
            <Col xs={6}>
              <RInput lable={"Account Created Date"} value={userData?.createdAt} keyName={"createdAt"} disabled={true}/>
            </Col>
            <Col xs={6}>
              <RInput lable={"Failed Login Attempts"} value={userData?.failedLoginAttempts} keyName={"failedLoginAttempts"} disabled={true}/>
            </Col>
          </Row>
        </div>
      </div>

      <div>
        <div>
          <Row className="input-row">
            <Col xs={6}>
              <RInput lable={"Last Login:"} value={userData?.lastLogin} keyName={"lastLogin"} disabled={true}/>
            </Col>
            <Col xs={6}>
              <RInput lable={"Current Login:"} value={userData?.currentLogin} keyName={"currentLogin"} disabled={true}/>
            </Col>
          </Row>
        </div>
      </div>

      <div>
        <h1 className="sideHeadingSty">User Status</h1>
        <div>
          <Row className="input-row">
            <Col xs={6}>
              <RInput lable={"Is User’s Account Locked?"} value={userData?.isLocked}  disabled={true}/>
            </Col>
            <Col xs={6} style={{display:"flex",justifyContent:"flex-end",alignItems:"flex-end"}}>
             
              <Button onClick={()=>{
                setResetLoginAtmLoading(true)
                    const token = sessionStorage.getItem("token");
                   updateUser(token,{status:false,type:"user",updates:{failedLoginAttempts:0},userId:userId}).then((res)=>{
                    console.log(res);
                  }).catch((err)=>{
                    console.log(err);
                  }).finally(()=>{
                    setReloadUserData((prev)=>(!prev));
                    setResetLoginAtmLoading(false)
                  })
                }}>
                {resetLoginAtmLoading ? <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                /> :"Reset Failed Login Attempts Count" }  
              </Button>

            </Col>
          </Row>
        </div>
      </div>

      <div>
        <div>
          <Row className="input-row">
            <Col xs={6}>
              <RInput lable={"Password Last Reset on"} value={userData?.passwordUpdatedAt }  disabled={true}/>
            </Col>
            <Col xs={6} style={{display:"flex",justifyContent:"flex-end",alignItems:"flex-end"}}>
              
              {/* <Button onClick={()=>{

                }}>
              Send Password Reset Link
              </Button> */}

            </Col>
          </Row>
        </div>
      </div>

      <div>
        <h1 className="sideHeadingSty">Security</h1>
        <div>
          <Row className="input-row">
            <Col xs={6}>
              <RInput lable={"Security Pin Set?"} disabled={true}  value={userData?.loginSecurityPin ? true:false}/>
            </Col>
            <Col xs={6}>
              <RInput lable={"Security PIN"} disabled={true}  value={userData?.loginSecurityPin}/>
            </Col>
          </Row>
        </div>
      </div>
      <div style={{display:"flex",justifyContent:"flex-end",alignItems:"flex-end",marginTop:"2vh"}}>
              {/* <Button onClick={()=>{
                  
                }}>
               Send Security PIN Reset Link
              </Button> */}
    </div>
    </div>
  );
}

export default UserSecurityStatus;
