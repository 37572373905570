import React from 'react';
import "./NavButton.css"


function NavButton({icon,name,activeColor,isActive=false,textColor,onClick}) {
    return (
        <div className='NavButton-con' style={{backgroundColor: isActive ? activeColor ? activeColor:"#509CDB" : "transparent"}} onClick={onClick}>
            <div className='Nav-icon'>
            {icon}
          
            </div>
            <div>
                <p className='Nav-btn-name' style={{color : textColor ? textColor : "white"}}>
                    {name}
                </p>
            </div>
            
        </div>
    );
}

export default NavButton;