import React from "react";
import { Backdrop, Box, CircularProgress, Modal, Paper } from "@mui/material";
import { Select as BaseSelect, selectClasses } from "@mui/base/Select";

import { Option as BaseOption, optionClasses } from "@mui/base/Option";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { RiEditCircleFill } from "react-icons/ri";
import { IoMdAddCircle } from "react-icons/io";
import { FaDownload, FaUser } from "react-icons/fa";
import { TiDelete } from "react-icons/ti";
import { getAllpromoData } from "../../../API/UsersAPI/UsersApi";
import { HiOutlineArrowSmLeft, HiOutlineArrowSmRight } from "react-icons/hi";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import {
  getPromoReports,
  getSinglePromoReports,
} from "../../../API/ReportsAPI/ReportsApi";
import JsonView from "react18-json-view";

const recordCountSelecter = [10, 15, 20, 25, 30];
function PromotionReport(props) {
  const navigator = useNavigate();
  const [promoData, setpromoData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchBtnClicked, setSearchBtnClicked] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [recordCount, setRecordCount] = useState(recordCountSelecter[0]);
  const [singlePromo, setSinglePromo] = useState({});
  const [singlePromoLoading, setSinglePromoLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    console.log(token);
    setLoading(true);

    getPromoReports(token, pageCount, recordCount, searchQuery)
      .then((res) => {
        if (typeof res.data === "object") {
          setpromoData(
            res.data?.populatedOffers.map((item) => {
              return { Actions: item._id, ...item };
            })
          );
        }

        // alert(typeof res.data)
        console.log("data fetched", res);
        // alert("users data fetched");
        setLoading(false);
      })
      .catch((error) => {
        // alert("users data fetched" + error);
        console.log("error");
        setLoading(false);
        setpromoData([]);
      });
  }, [pageCount, recordCount, searchBtnClicked]);

  const handleCSVExport = () => {
    try {
      // Convert JSON to CSV
      const csv = Papa.unparse(promoData);

      // Create a Blob object for the CSV data
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      // Use file-saver to save the file
      saveAs(blob, "data.csv");
    } catch (err) {
      console.error("Error converting JSON to CSV:", err);
    }
  };

  const handleCSVSinglePromoExport = () => {
    try {
      // Convert JSON to CSV
      const csv = Papa.unparse([singlePromo?.promotionDetails]);

      // Create a Blob object for the CSV data
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      // Use file-saver to save the file
      saveAs(blob, "data.csv");
    } catch (err) {
      console.error("Error converting JSON to CSV:", err);
    }
  };
  const CustomCellComponent = ({ value }) => {
    // console.log(value)
    return (
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div
          onClick={() => {
            const token = sessionStorage.getItem("token");
            setSinglePromo({});
            getSinglePromoReports(token, value)
              .then((res) => {
                setSinglePromo(res.data);
                handleOpen();
              })
              .catch((err) => {
                console.log(err);
              });
          }}
        >
          <FaDownload
            style={{ color: "#EEB50C", fontSize: "1.4vw", cursor: "pointer" }}
          />
        </div>
      </div>
    );
  };

  const getColumns = () => {
    const filteredEntries = Object.entries(
      Object.assign({}, ...promoData)
    ).filter(
      ([key, value]) =>
        typeof value !== "function" &&
        typeof value !== "object" &&
        typeof value !== "symbol" &&
        typeof value !== "undefined"
    );
    const filteredObject = Object.fromEntries(filteredEntries);
    const dataKeys = Object.keys(filteredObject);
    return dataKeys.map((item, index) => {
      return index === 0
        ? {
            field: item,
            headerName: item,
            align: "center",
            headerAlign: "center",
            sortable: false,
            editable: false,
            renderCell: (params) => {
              return <CustomCellComponent value={params.value} />;
            },
            width: 150,
          }
        : {
            field: item,
            headerName: item,
            align: "center",
            headerAlign: "center",
            sortable: false,
            editable: true,
          };
    });
  };

  const columns: GridColDef[] = [...getColumns()];

  const rows = promoData.map((item, index) => {
    const filteredEntries = Object.entries(item ?? {}).filter(
      ([key, value]) =>
        typeof value !== "function" &&
        typeof value !== "object" &&
        typeof value !== "symbol" &&
        typeof value !== "undefined"
    );
    const filteredObject = Object.fromEntries(filteredEntries);
    return { ...filteredObject, id: index };
  });
  return (
    <div>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={singlePromoLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />{" "}
      </Backdrop>

      <div>
        <div
          style={{
            marginTop: "3vh",
            display: "flex",
            justifyContent: "space-between",
            padding: "0% 3.5%",
            marginBottom: "2%",
            alignItems: "center",
          }}
        >
          <div>
            <h1 className="sideHeadingSty" style={{ margin: "0" }}>
              Promotion Reports
            </h1>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              id="button-addon2"
              style={{
                backgroundColor: "#0079FF",
                color: "white",
                border: "none",
                marginLeft: 10,
              }}
              onClick={() => {
                handleCSVExport();
              }}
            >
              Export CSV
            </Button>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ width: 400 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100vw",
              alignItems: "center",
              height: "100vh",
              overflow:"scroll"
            }}
          >
           
            <div style={{backgroundColor:"#ffffff",padding:"10%"}}>
                <div>
            <Button onClick={()=>{
                 setSinglePromo({});
                 setOpen(false)
            }}>
                Close
            </Button>
            <Button style={{marginLeft:"2%"}} onClick={()=>{handleCSVSinglePromoExport()}}>
            Export CSV
            </Button>
            </div>
            <JsonView src={singlePromo} theme="vscode"  dark style={{color:"black"}}/>
            </div>
          </div>
        </Box>
      </Modal>
      <Paper sx={{ height: "70vh", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              // paginationModel: { pageSize: recordCount },
              meta: { hasNextPage: true },
            },
          }}
          pageSizeOptions={[5, 10, 15]}
          paginationMode="client"
          pagination
          // estimatedRowCount={500}
          // hideFooterSelectedRowCount={true}
          rowSpacingType="border"
          // autoPageSize
          hideFooterPagination
          hideFooter
          rowCount={1000}
          onPaginationMetaChange={() => {
            alert("changed");
          }}
          // checkboxSelection
          sx={{ border: 0 }}
          loading={loading}
          onProcessRowUpdateError={(error) => {
            alert("on Process Row Update Error");
          }}
          disableRowSelectionOnClick
          onPaginationModelChange={(model) => {
            // setPaginationModel(model);
            // console.log(model);
            // getMoreData();
          }}
          onRowDoubleClick={(item) => {
            alert(item?.row?._id);
          }}
          // autosizeOptions={{
          //     columns: ['dob', 'status', 'createdBy'],
          //     includeOutliers: false,
          //     includeHeaders: false,
          //   }}
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "1%",
            paddingRight: "2%",
          }}
        >
          <div
            style={{
              width: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                // border: "1px solid black",
                width: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ margin: "0", color: "black", margin: "0 0.5vw" }}>
                Row per page
              </p>
            </div>

            <div
              style={{
                // border: "1px solid black",
                width: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Form.Select
                size="sm"
                onChange={(event) => {
                  setRecordCount(event.target.value);
                }}
                disabled={loading}
              >
                {recordCountSelecter.map((value, index) => {
                  return <option key={index + "values"}>{value}</option>;
                })}
              </Form.Select>

              <p style={{ margin: "0", color: "black", margin: "0 0.5vw" }}></p>
            </div>
            <Button
              onClick={() => {
                setPageCount((page) => page - 1);
              }}
              disabled={pageCount <= 1 || loading}
            >
              <HiOutlineArrowSmLeft />
            </Button>

            <div
              style={{
                // border: "1px solid black",
                width: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ margin: "0", color: "black", margin: "0 0.5vw" }}>
                {pageCount}
              </p>
            </div>

            <Button
              onClick={() => {
                // getMoreData();
                setPageCount((page) => page + 1);
              }}
              disabled={loading}
            >
              <HiOutlineArrowSmRight />
            </Button>
          </div>
        </div>
      </Paper>
    </div>
  );
}

export default PromotionReport;
