import React, { useEffect, useState } from 'react';
import "./Advertising.css"
import Layout from '../../components/Layout/Layout';
import { createAdvertisement, deleteAdvertisement, getAdvertisements, updateAdvertisement } from '../../API/AdvertisementsAPI/AdvertisementsAPI';
import { Button, Form, Image, Modal } from 'react-bootstrap';
import JsonView from 'react18-json-view';
import { Backdrop, CircularProgress } from '@mui/material';
import LeagueInput from '../../components/RInput/LeagueInput';
function Advertising(props) {
    const [selectedCard, setSelectedCard] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [advCards, setAdvCards] = useState([]);
    const [loading, setIsloading] = useState(false);
    const [showAdModal, setShowAdModal] = useState(false);
    const [adImage, setAdImage] = useState();
    const [isSaving] = useState(false);
    const [adLink, setAdLink] = useState('');
    const [regionalPriority, setRegionalPriority] = useState(0);
    const [adRegion, setAdRegion] = useState('');
    const [adWithinAppRouting, setAdWithinAppRouting] = useState('');
    const [adOutsideAppRouting, setAdOutsideAppRouting] = useState('');
    const [adType, setAdType] = useState();
    const [refresh, setRefresh] = useState(true)

    const handleShowModal = (card = null) => {
        if (card) {
            console.log(card, "CardDetails")
            // Editing existing ad
            setSelectedCard(card);
            setAdLink(card.link);
            setAdType(card.type);
            setAdRegion(card.region);
            setAdWithinAppRouting(card.withinAppRouting);
            setAdOutsideAppRouting(card.outsideAppRouting);
            setRegionalPriority(card.regionalPriority);
            setAdImage(card.imageUrl);
            setIsEditMode(true); // Set mode to editing
        } else {
            // Creating new ad
            setSelectedCard(null);
            setAdLink('');
            setAdType('');
            setAdRegion('');
            setAdWithinAppRouting('');
            setAdOutsideAppRouting('');
            setRegionalPriority('');
            setAdImage(null);
            setIsEditMode(false); // Set mode to creating
        }
        setShowAdModal(true); // Show the modal
    };

    const handleshowModalClose = () => { setShowAdModal(false); setAdImage(''); }

    const deleteCard = (id) => {
        const token = sessionStorage.getItem("token");
        alert("Proceed to delete the Advertisement!");
        deleteAdvertisement(token, id).then((res) => {
            console.log(res.data);
            setRefresh(res => !res);
        }).catch((err) => {
            console.log(err);
            //setShowAdModal(false);
            alert("Advertisement not Deleted, please try again!");
        })
    }
    const createAdv = () => {
        if (adImage) {
            const token = sessionStorage.getItem("token");
            createAdvertisement(token, adImage, adType, adOutsideAppRouting, adWithinAppRouting, adRegion, regionalPriority, adLink).then((res) => {
                setShowAdModal(false);
                setRefresh(res => !res);
                console.log(res?.data);
            }).catch((err) => {
                console.log(err);
                //setShowAdModal(false);
                alert("Advertisement not created, please try again!");
            })
        }
        else {
            alert("please fill all the fields");
        }
    }

    const updateAdv = () => {
        if (selectedCard) {
            const token = sessionStorage.getItem("token");
            updateAdvertisement(token, selectedCard?._id, adImage, adType, adOutsideAppRouting, adWithinAppRouting, adRegion, regionalPriority, adLink).then((res) => {
                setShowAdModal(false);
                setRefresh(res => !res);
                console.log(res?.data);
            }).catch((err) => {
                console.log(err);
                //setShowAdModal(false);
                alert("Advertisement not Updated, please try again!");
            })
        }
        else {
            alert("please fill all the fields");
        }
    }


    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onloadend = () => {
                console.log(reader.result);
                setAdImage(reader.result);

            };
            reader.readAsDataURL(file);
        } else {
            alert('Please select an image file.');
        }
    };

    useEffect(() => {
        const token = sessionStorage.getItem('token');
        setIsloading(true);
        if (token) {
            console.log('Token retrieved:', token);

            getAdvertisements(token)
                .then((res) => {
                    console.log(res.data);
                    setAdvCards(res.data);
                    setSelectedCard(res.data[0]);
                    setIsloading(false);
                })
                .catch(err => { console.log(err); setIsloading(false); });
        } else {
            console.log("Token Not Found");
            // Redirect to login page or display a message
            setIsloading(false);
        }
    }, [refresh]);
    return (
        <Layout>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "3%" }}>
                <Button variant='primary' onClick={() => { handleShowModal() }}>Add new</Button>
            </div>
            <Modal show={showAdModal} onHide={handleshowModalClose}>
                <Modal.Header >
                    <Modal.Title>{isEditMode ? "Update Advertisement" : "Create Advertisement"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LeagueInput lable={"Link"} value={adLink} setInput={setAdLink} />
                    <LeagueInput lable={"RegionalPriority"} value={regionalPriority} setInput={setRegionalPriority} type={"number"} />
                    <LeagueInput lable={"Region"} value={adRegion} setInput={setAdRegion} />
                    <LeagueInput lable={"WithinAppRouting"} value={adWithinAppRouting} setInput={setAdWithinAppRouting} />
                    <LeagueInput lable={"OutsideAppRouting"} value={adOutsideAppRouting} setInput={setAdOutsideAppRouting} />
                    <LeagueInput lable={"Ad Type"} value={adType} setInput={setAdType} />

                    <p className='inputLableSty'>Advertisement Image : </p>
                    <div>
                        <Form.Group controlId="formFile" className="mb-3" >
                            <Form.Label>Upload Image</Form.Label>
                            <Form.Control type="file" accept='image/*' onChange={handleFileChange} />
                            {adImage && (
                                <Image
                                    src={adImage}
                                    className="Logo-imgs"
                                    style={{ marginRight: '10px', marginLeft: '10px' }}
                                />
                            )}
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleshowModalClose} disabled={isSaving}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={isEditMode ? updateAdv : createAdv} disabled={isSaving}>
                        {isEditMode ? (isSaving ? "Updating..." : "Update") : (isSaving ? "Saving..." : "Save Changes")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={loading}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" /> </Backdrop>
            <div style={{ display: 'flex', width: '100%', height: '90vh', alignItems: 'center' }}>
                <div style={{
                    justifyContent: 'center',
                    alignItems: "center", 
                    height: '85vh',
                    width: '20%',
                    overflowY: 'scroll',
                    padding: '10px',    // Add padding for better spacing
                    borderRight: '1px solid lightgrey' 
                }} className='advertising-Cards-cont'>
                    {advCards.map((card, index) => (
                        <div key={index}
                            onClick={() => setSelectedCard(card)} // Set the selected card on click
                            style={{ cursor: 'pointer' ,marginBottom: '10px',padding:"10px 0px",display:"flex",justifyContent:"center",alignItems:"center",backgroundColor: card?._id === selectedCard?._id ? "#152259" :"transparent",borderRadius:"10px"}}>
                            <Image
                                key={index} // Always add a unique key when rendering lists
                                src={card?.imageUrl}
                                width={250}
                                height={150}
                                alt='adv banners'
                                
                            />
                        </div>

                    ))}
                </div>
                <div style={{
                    justifyContent: 'center',
                    alignItems: "center", // Align items to the start for vertical layout
                    height: '85vh',
                    width: '80%',
                    display: 'flex',
                    flexDirection: 'column', // Arrange children in a column
                    overflowY: 'auto' // Enable vertical scrolling
                }}>
                    <div style={{
                        justifyContent: 'center',
                        alignItems: "center",
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}>

                        {selectedCard ? (
                            <>
                                
                                <JsonView
                                    src={selectedCard}
                                    theme="vscode"
                                    style={{ color: "ThreeDDarkShadow" }}
                                    editable={false}
                               
                                    onEdit={(newValue) => setSelectedCard(newValue)} // Handle edits
                                />
                                <div style={{ display: "flex", justifyContent: "center", marginTop: "3%", width: '100%', alignItems: 'center',padding:10 }}>
                                    <Button variant='danger' onClick={() => { deleteCard(selectedCard?._id) }}>Delete</Button>
                                    <Button variant='primary' onClick={() => { handleShowModal(selectedCard) }} style={{ marginLeft: '5%' }}>update</Button>
                                </div>

                            </>

                        ) : (
                            <p>Please select an image to edit its details.</p>
                        )}
                    </div>

                </div>

            </div>
        </Layout>
    );
}

export default Advertising;