import React, { useEffect, useState } from 'react';
import "./NavBars.css"
import { Image } from 'react-bootstrap';
import { LOGO_ICON_WHITE } from '../../../constants/images';
import { COL_PRIMARY } from '../../../constants/colors';
import NavButton from '../../NavButton';
import { TbAd, TbChartBar, TbDatabase, TbLayoutDashboard, TbMail, TbSettings, TbSettings2, TbUsers } from 'react-icons/tb';
import { BsServer } from "react-icons/bs";
import { useLocation, useNavigate } from 'react-router-dom';
import { GiSettingsKnobs } from 'react-icons/gi';
import { BsArchiveFill, BsCheckCircle } from 'react-icons/bs';
import { HiOutlineLogout } from 'react-icons/hi';
import { getAdminData } from '../../../API/HomeAPI/HomeAPI';
import { Alert, Backdrop } from '@mui/material';
// import CheckIcon from '@mui/icons-material/Check';
import { restartServer } from '../../../API/RestartServer/ServerAPI';


function NavBars(props) {
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const [adminData, setAdminData] = useState(() => {

    try {
      return JSON.parse(localStorage.getItem("adminData")) ?? {};
    }
    catch {
      return {};
    }
  });
  const [showAlert, setShowAlert] = useState(false);
  console.log("admin data", adminData);
  const NavBtnData = [
    {
      name: "DashBoard",
      icon: <TbLayoutDashboard style={{ color: "white", fontSize: 20, fontWeight: "700" }} />,
      pathName: "/DashBoard"

    },
    {
      name: "Users",
      icon: <TbUsers style={{ color: "white", fontSize: 20, fontWeight: "700" }} />,
      pathName: "/Users"
    },
    {
      name: "Advertising",
      icon: <TbAd style={{ color: "white", fontSize: 20, fontWeight: "700" }} />,
      pathName: "/Advertising"
    },
    // {
    //   name: "Email Settings",
    //   icon: <TbMail style={{ color: "white", fontSize: 20, fontWeight: "700" }} />,
    //   pathName:"/EmailSettings"
    // },
    {
      name: "League Config",
      icon: <GiSettingsKnobs style={{ color: "white", fontSize: 23, fontWeight: "700" }} />,
      pathName: "/LeagueConfig"
    },
    {
      name: "Reports",
      icon: <TbChartBar style={{ color: "white", fontSize: 20, fontWeight: "700" }} />,
      pathName: "/Reports"
    },
    {
      name: "Config Settings",
      icon: <TbSettings style={{ color: "white", fontSize: 20, fontWeight: "700" }} />,
      pathName: "/DBSettings"
    }
  ];
  const location = useLocation();
  //   alert(location.pathname)
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      navigation(location.pathname);
    } else {
      navigation("/");
    }
  }, [location.pathname])

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    console.log("admin data loacal", localStorage.getItem("adminData"));
    if (token && !localStorage.getItem("adminData")) {

      getAdminData(token).then((res) => {
        // console.log(res);
        setAdminData(res.data);
        localStorage.setItem("adminData", JSON.stringify(res.data));
      }).catch((err) => {
        console.log(err);
      })
    }

  }, [])

  const getRootPath = (pathName) => {
    try {
      const root = "/" + pathName.split("/")[1]
      // alert(root)
      console.log(root)
      return root ?? "/"
    }
    catch (err) {
      return "/"
    }
  }

  const updateServer = () => {
    const token = sessionStorage.getItem('token');
    setLoading(true);
    console.log(token)
      // Show confirmation alert before restarting the server
      const userConfirmed = window.confirm(
        "Are you sure? This will restart the server and cannot be reverted back."
      );
  
      if (userConfirmed && token) {
        restartServer(token)
          .then((res) => {
            console.log(res.data, "Restarted Server Successfully");
            setLoading(false);
            setShowAlert(true);
            // window.alert("Server Restarted Successfully");
            setTimeout(() => {
              setShowAlert(false); // Hides alert after 1 second
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      } else {
        setLoading(false); // Stop loading if user cancels
      }
  }

  return (
    <div >
      {
        showAlert && <Alert variant="filled" icon={<BsCheckCircle />} severity="success" onClose={() => { setShowAlert(false) }}>
          Server restarted successfully!
        </Alert>
      }
      {loading && <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={loading}
      // onClick={handleClose}
      ></Backdrop>
      }
      <div className='Nav-hdr-con'>
        <div className='Nav-hdr'>
          <div className='Logo-con'>
            <Image src={adminData?.profilePicture ?? LOGO_ICON_WHITE} className='Logo-img' />
          </div>
          <div className='name-con'>
            <p className='admin-name'>{adminData?.username}</p>
            <p className='admin-login-name'>Last Login:</p>
            <p className='admin-date'>{formatDate(adminData?.lastLogin)}</p>
            {/* <p className='admin-date'>HH:MM AM/PM</p> */}
          </div>
        </div>
      </div>
      <div className='Nav-Btn-con'>
        <div className='Nav-Btn'>
          {NavBtnData.map((item, index) => {
            return <NavButton icon={item.icon} name={item.name} key={index + item.name} isActive={item.pathName === getRootPath(location.pathname)} onClick={() => { navigation(`${item.pathName + (item.pathName === "/Users" ? "/UsersTableView" : "")}`) }} />
          })}
        </div>
      </div>

      <div className='Nav-ftr-con'>
        <div className='Nav-Btn'>
          <NavButton name={'Restart Server'} textColor={'#8A8A8F'} activeColor={"#FFFFFFBF"} isActive={false} icon={<BsServer style={{ color: "#8A8A8F", fontSize: 20, fontWeight: "700" }} />} onClick={updateServer} style={{ cursor: "pointer" }} />
          {/* <NavButton name={'Archive'} textColor={'#8A8A8F'} activeColor={"#FFFFFFBF"} isActive={"/Archive" === getRootPath(location.pathname)} icon={<BsArchiveFill style={{ color: "#8A8A8F", fontSize: 20, fontWeight: "700" }} />} onClick={() => { navigation('/Archive') }} /> */}
          <NavButton name={'Log out'} textColor={'#EF4B4B'} activeColor={"#FFFFFFBF"} icon={<HiOutlineLogout style={{ color: "#EF4B4B", fontSize: 20, fontWeight: "700" }} />} onClick={() => { localStorage.clear(); sessionStorage.clear(); navigation("/"); }} />
        </div>
      </div>

    </div>
  );
}

export default NavBars;


export function formatDate(dateString) {
  const date = new Date(dateString);

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short'
  };

  return date.toLocaleString('en-US', options);
}