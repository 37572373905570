import axios from "axios";
import config from "../../constants/config";

export async function getConfigData(token) {
    console.log('called getConfigData() method');
    return await axios.get(config.API_URL+"/admin/configData", {
      headers: {
        Authorization: token,
      },
    });
  }


  export async function postConfigData(token,configId,data) {
    console.log('called postConfigData() method');
    return await axios.put(config.API_URL+"/admin/configData", 
        {   
            id:configId,
           updateData:data
        },{
      headers: {
        Authorization: token,
      },
    });
  }